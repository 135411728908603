import StoreType from "./storeType"

const login: StoreType = {
  namespaced: true,
  state: {
    isLogin: false
  },
  mutations: {
    loginSign(state, sign: boolean) {
      state.isLogin = sign;
    }
  },
  actions: {
    
  }
}

export default login;