import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Highlight from './highlight';

createApp(App)
.use(store)
.use(router)
.use(Antd)
.use(Highlight)
.mount('#app')
