import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { getCookie } from "@/utils/cookie";

const Index = () => import("@/views/index/index.vue")
const Home = () => import("@/views/home/home.vue")
const Search = () => import("@/views/search/search.vue")
const Notes = () => import("@/views/notes/notes.vue")
const Smart = () => import("@/views/smart/smart.vue")
const Resources = () => import("@/views/resources/resources.vue")
const Tools = () => import("@/views/tools/tools.vue")
const RmWatermark = () => import("@/views/tools/rm-watermark/rm-watermark.vue")
const CiYun = () => import("@/views/tools/ciyun/ciyun.vue")
const Analysis = () => import("@/views/tools/analysis/analysis.vue")
const About = () => import("@/views/about/about.vue")
const Community = () => import("@/views/community/community.vue")
const Login = () => import("@/views/login/login.vue")
const Article = () => import("@/views/article/article.vue")
const Admin = () => import("@/views/admin/admin.vue")
const PublishingCreation = () => import("@/views/admin/publishing-creation/publishing-creation.vue")
const Published = () => import("@/views/admin/published/published.vue")
const ArticleEditor = () => import("@/views/admin/article-editor/article-editor.vue")
const Links = () => import("@/views/admin/links/links.vue")
const LeavingMsg = () => import("@/views/admin/leaving-msg/leaving-msg.vue")
const AdminComment = () => import("@/views/admin/comment/comment.vue")
const AdminFiles = () => import("@/views/admin/files/files.vue")

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
          title: "木小天博客"
        }
      },
      {
        path: '/search',
        name: 'Search',
        component: Search
      },
      {
        path: '/notes',
        name: 'Notes',
        component: Notes,
        meta: {
          title: "随笔-木小天博客"
        }
      },
      {
        path: '/smart',
        name: 'Smart',
        component: Smart,
        meta: {
          title: "技术-木小天博客"
        }
      },
      {
        path: '/resources',
        name: 'Resources',
        component: Resources,
        meta: {
          title: "资源-木小天博客"
        }
      },
      {
        path: '/tools',
        name: 'Tools',
        component: Tools,
        meta: {
          title: "在线工具-木小天博客"
        }
      },
      {
        path: '/tools/rm-watermark',
        name: 'RmWatermark',
        component: RmWatermark,
        meta: {
          title: "在线去除抖音、快手水印-木小天博客"
        }
      },
      {
        path: '/tools/ciyun',
        name: 'CiYun',
        component: CiYun,
        meta: {
          title: "在线生成词云-木小天博客"
        }
      },
      {
        path: '/tools/analysis',
        name: 'Analysis',
        component: Analysis,
        meta: {
          title: "在线VIP视频解析-木小天博客"
        }
      },
      {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
          title: "关于我-木小天博客"
        }
      },
      {
        path: '/community',
        name: 'Community',
        component: Community,
        meta: {
          title: "社区"
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          title: "登录"
        }
      },
      {
        path: '/article',
        name: 'Article',
        component: Article,
      },
      {
        path: "/admin",
        name: "Admin",
        component: Admin,
        beforeEnter: (to, from, next) => {
          const sign = getCookie("token") && getCookie("user");
          if (!sign) {
            return next("/login");
          }
          next();
        },
        meta: {
          title: "个人中心"
        },
        children: [
          {
            path: "/admin",
            name: "PublishingCreation",
            component: PublishingCreation,
            meta: {
              title: "发表创作-木小天博客"
            }
          },
          {
            path: "/admin/published",
            name: "Published",
            component: Published,
            meta: {
              title: "已发布创作-木小天博客"
            }
          },
          {
            path: "/admin/article-editor",
            name: "ArticleEditor",
            component: ArticleEditor,
            meta: {
              title: "修改文章-木小天博客"
            }
          },
          {
            path: "/admin/links",
            name: "Links",
            component: Links,
            meta: {
              title: "友情链接-木小天博客"
            }
          },
          {
            path: "/admin/leaving-msg",
            name: "leavingMessage",
            component: LeavingMsg,
            meta: {
              title: "管理留言-木小天博客"
            }
          },
          {
            path: "/admin/comment",
            name: "AdminComment",
            component: AdminComment,
            meta: {
              title: "管理评论-木小天博客"
            }
          },
          {
            path: "/admin/files",
            name: "AdminFiles",
            component: AdminFiles,
            meta: {
              title: "管理文件-木小天博客"
            }
          }
        ]
      }
    ]
  },
  // 找不到路由跳转至首页
  {
    path: "/:w+",
    name: "*",
    redirect: "/"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


// 全局拦截
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router
