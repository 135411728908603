<template>
  <router-view/>
</template>

<style lang="scss">
  #app {
    background: #F6F6F6;
    // min-width: 1300px;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }

  .text-wrapper {

    h1 {
      font-size: 28px !important;
    }
  }

  .title-wrapper {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;

    .title {
      color: #454545;
      line-height: 40px;
      display: inline-block;
      font-weight: 600;
      border-bottom: 4px solid #2254f4;
    }
  }

  // 移动端
  @media screen and (max-width: 800px) {
    .center {
      width: 95%;
    }
  }
</style>
