import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css'


const Highlight = function (Vue: any) {
  Vue.directive('highlight',function (el: any) {
    const blocks = el.querySelectorAll('pre code');
    blocks.forEach((block: any)=>{
      hljs.highlightBlock(block)
    })
  })
}

export default Highlight